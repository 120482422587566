import React, { useEffect } from 'react';

import { Category } from './category';
import { BUILDER_TOGGLE_KEY } from './const';

import type { Battle } from '~/client/battle';
import type { InputTouchSwipeEvent } from '~/client/core/input/touch/types';

import { ScreenGrid } from '~/client/battle/ui/components/stages/started/screen-grid';
import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { ScreenType } from '~/client/battle/ui/types';
import { InputKeyboard } from '~/client/core/input/keyboard';
import { InputTouch } from '~/client/core/input/touch';
import { InputTouchSwipeDirection } from '~/client/core/input/touch/types';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { useEvent } from '~/client/core/ui/hooks/use-event';
import { BuildingCategory } from '~/shared/battle/entity/building/types';

export const BuilderScreen: React.FC = () => {
  const { builder } = useRoom<Battle>();
  const { visible, toggleScreen } = useScreen(ScreenType.Builder);

  const categories = Object.values(BuildingCategory);

  const handleSelect = () => {
    toggleScreen(false);
  };

  useEvent(InputTouch.onTouchSwipe, (event: InputTouchSwipeEvent) => {
    if (builder.isBuild()) {
      return;
    }

    if (event.direction === InputTouchSwipeDirection.Left) {
      toggleScreen(true);
    } else if (event.direction === InputTouchSwipeDirection.Right) {
      toggleScreen(false);
    }
  }, [toggleScreen]);

  useEvent(InputKeyboard.onKeyUp, (event: KeyboardEvent) => {
    if (event.key === BUILDER_TOGGLE_KEY) {
      event.preventDefault();

      toggleScreen(false);
    }
  }, [toggleScreen]);

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.key === BUILDER_TOGGLE_KEY) {
      event.preventDefault();
      toggleScreen(true);
    }
  }, [toggleScreen]);

  useEffect(() => {
    if (visible) {
      builder.setVariant(null);
    }
  }, [visible]);

  return visible && (
    <ScreenGrid>
      {categories.map((category) => (
        <Category
          key={category}
          category={category}
          onSelect={handleSelect}
        />
      ))}
    </ScreenGrid>
  );
};
