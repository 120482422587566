import React from 'react';

import type { KeyAction } from './types';

import { KeyboardKey } from '~/client/core/input/keyboard/ui/components/key';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  action: KeyAction;
  target: string;
};

export const Key: React.FC<Props> = ({ children, action, target }) => {
  const language = useLanguage();

  return (
    <Section direction='vertical' gap={6} className={styles.content}>
      <Section direction='horizontal' align='center' gap={12}>
        <Text size='m' bold spacing uppercase>{language(action)}</Text>
        <KeyboardKey size='l'>{target}</KeyboardKey>
        <Text size='m' bold spacing uppercase>{language('To')}</Text>
      </Section>
      <Text size='m' bold spacing uppercase>{children}</Text>
    </Section>
  );
};
