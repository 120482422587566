import React, { useCallback } from 'react';

import type { Battle } from '~/client/battle';

import { InputKeyboard } from '~/client/core/input/keyboard';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Button } from '~/client/core/ui/components/button';
import { FORM_SUBMIT_KEY } from '~/client/core/ui/components/form/const';
import { Overlay } from '~/client/core/ui/components/overlay';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useEvent } from '~/client/core/ui/hooks/use-event';
import { BattleMessage } from '~/shared/battle/types';

import styles from './styles.module.scss';

export const StageWaitReady: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();

  const handleClick = useCallback(() => {
    battle.messages.send(BattleMessage.GetReady, void {});
  }, []);

  useEvent(InputKeyboard.onKeyDown, (event: KeyboardEvent) => {
    if (event.code === FORM_SUBMIT_KEY) {
      handleClick();
    }
  }, [handleClick]);

  return (
    <Overlay blur>
      <Section direction='horizontal' align='center' gap={64}>
        <div className={styles.avatar}>
          <img src={battle.scene.snaps.get('PlayerAvatar')} />
        </div>
        <Section direction='vertical' align='left' gap={32}>
          <Section direction='vertical' align='left' gap={16}>
            <Text size='xl' view='primary' bold uppercase spacing>
              {language('YourGoal')}
            </Text>
            <Text size='m'>
              {language('GoalDescription')}
            </Text>
          </Section>
          <Button onClick={handleClick}>
            {language('Start')}
          </Button>
        </Section>
      </Section>
    </Overlay>
  );
};
