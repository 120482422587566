import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { ScreenState } from '../state';

import type { Battle } from '../..';
import type { ScreenType } from '../types';

import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Storage } from '~/client/core/storage';

export function useScreen(target?: ScreenType) {
  const battle = useRoom<Battle>();

  const [screen, setScreen] = useRecoilState(ScreenState);
  const visible = screen === target;

  const openScreen = useCallback((type: ScreenType | undefined = target) => {
    if (visible) {
      return;
    }

    if (!type) {
      throw Error('Unknown target screen');
    }

    Storage.set('Screen', type);
    setScreen(type);

    battle.setPause(true);
  }, [visible]);

  const closeScreen = useCallback(() => {
    if (!visible) {
      return;
    }

    Storage.remove('Screen');
    setScreen(null);

    battle.setPause(false);
  }, [visible]);

  const toggleScreen = useCallback((opened: boolean) => {
    if (opened) {
      openScreen();
    } else {
      closeScreen();
    }
  }, [openScreen, closeScreen]);

  return {
    visible,
    screen,
    closeScreen,
    openScreen,
    toggleScreen,
  };
}
