import { Events } from 'make-event';
import { MathUtils } from 'three';

import { MOB_HIT_AUDIO } from './const';
import { Corpse } from './corpse';
import { MobUI } from './ui';
import { NPC } from '..';

import type { MobConfig } from './types';
import type { Vector3Like } from 'three';
import type { Battle } from '~/client/battle';
import type { ModelType } from '~/client/core/assets/types';
import type { Messages } from '~/client/core/messages';
import type { RenderItemMesh } from '~/client/core/render-item/types';
import type { EntityDamagePayload } from '~/shared/battle/entity/types';
import type { AttackAreaParams } from '~/shared/battle/entity/unit/attack-area/types';
import type { MobMessagePayload, MobSchema } from '~/shared/battle/entity/unit/npc/mob/types';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { AudioType } from '~/client/core/audio/types';
import { MOB_ATTACK_PROCEED_TIMEOUT, MOB_FREEZE_MULTIPLIER } from '~/shared/battle/entity/unit/npc/mob/const';
import { MobMessage } from '~/shared/battle/entity/unit/npc/mob/types';
import { BattleStage } from '~/shared/battle/types';

import './resources';

export abstract class Mob extends NPC {
  declare public schema: MobSchema;

  declare protected readonly messages: Messages<MobMessagePayload>;

  private freezen: boolean = false;

  private readonly modelType: ModelType;

  public readonly baseMeshes: RenderItemMesh[] = [];

  private readonly attackVariantsCount: number = 0;

  public stateIcon: Nullable<SVGIcon> = null;

  public readonly onChangeStateIcon = Events.make<Nullable<SVGIcon>>();

  constructor(battle: Battle, config: MobConfig, schema: MobSchema) {
    super(battle, config, schema);

    this.renderItem.eachMeshes((mesh) => {
      this.baseMeshes.push(mesh);
    });

    this.modelType = config.model;

    const animations = Array.from(this.renderItem.animator.actions.keys());
    this.attackVariantsCount = animations.filter((action) => (
      action.indexOf('attack') === 0
    )).length;

    this.setUI(MobUI);

    this.createIndicator();
    this.createMarker({
      scale: 1.0,
    });

    this.schema.live.listen('health', (health) => {
      if (health <= 0) {
        this.onDead();
      }
    });

    this.onBattleFinish = this.onBattleFinish.bind(this);
    this.battle.onFinish(this.onBattleFinish);
  }

  override destroy(): void {
    super.destroy();

    this.removeFrostEffect();

    if (
      this.renderItem.visible &&
      this.battle.state.stage === BattleStage.Started
    ) {
      this.createCorpse();
    }

    this.battle.onFinish.unsubscribe(this.onBattleFinish);

    this.onChangeStateIcon.clear();
  }

  private onBattleFinish() {
    this.renderItem.animator.paused = true;
  }

  private createCorpse() {
    new Corpse(this.battle, {
      model: this.modelType,
      position: this.renderItem.position,
      rotation: this.renderItem.object.rotation,
    });
  }

  public setStateIcon(icon: SVGIcon) {
    this.stateIcon = icon;
    this.onChangeStateIcon.invoke(icon);
  }

  public removeStateIcon() {
    this.stateIcon = null;
    this.onChangeStateIcon.invoke(null);
  }

  public setBaseMaterial(type: MaterialType) {
    const material = Assets.getMaterial(type);
    this.baseMeshes.forEach((mesh) => {
      mesh.material = material;
    });
  }

  private createFrostEffect(): void {
    if (this.freezen) {
      return;
    }

    this.renderItem.animator.timeScale = MOB_FREEZE_MULTIPLIER;
    this.freezen = true;

    this.renderItem.setMaterial(MaterialType.UnitFrost);
  }

  private removeFrostEffect(): void {
    if (!this.freezen) {
      return;
    }

    this.renderItem.animator.timeScale = 1.0;
    this.freezen = false;

    this.renderItem.setMaterial(MaterialType.Unit);
  }

  override handleMessages(): void {
    super.handleMessages();

    this.messages.on(MobMessage.Attack, ({ targetPosition }) => {
      this.onAttack(targetPosition);
    });

    this.messages.on(MobMessage.Freeze, ({ duration }) => {
      this.onFreeze(duration);
    });
  }

  protected onDamage({ type }: EntityDamagePayload) {
    const audio = MOB_HIT_AUDIO[type] ?? AudioType.MobHit;
    this.playAudio(audio);
  }

  protected onDead() {
    this.playAudio(AudioType.MobDead);
  }

  protected onAttack(targetPosition: Vector3Like): void {
    const variant = MathUtils.randInt(1, this.attackVariantsCount);
    this.renderItem.animator.play(`attack${variant}`, {
      repeat: false,
    });

    this.rotateToPosition(targetPosition);

    this.timeouts.add(() => {
      this.playAudio(AudioType.MobAttack);
    }, MOB_ATTACK_PROCEED_TIMEOUT / 2);
  }

  override onDisplayAttackArea(params: AttackAreaParams) {
    super.onDisplayAttackArea(params);

    this.renderItem.animator.play('extra_attack', {
      repeat: false,
    });

    this.playAudio(AudioType.MobExtraAttack);
  }

  protected onFreeze(duration: number): void {
    this.createFrostEffect();
    this.playAudio(AudioType.MobFrost);

    this.timeouts.add(() => {
      this.removeFrostEffect();
    }, duration);
  }

  protected onChangeMoveState(moving: boolean): void {
    if (moving) {
      if (this.battle.state.stage === BattleStage.Finished) {
        return;
      }

      this.renderItem.animator.play('run');
    } else if (!this.freezen) {
      this.renderItem.animator.stop('run');
    }
  }
}
