import cn from 'classnames';
import React, { useCallback } from 'react';

import { ScreenType } from '../../../../types';

import { useScreen } from '~/client/battle/ui/hooks/use-screen';
import { Interactive } from '~/client/core/ui/components/interactive';

import styles from './styles.module.scss';

import IconMenu from './icons/menu.svg';

export const MenuButton: React.FC = () => {
  const { visible, toggleScreen } = useScreen(ScreenType.Menu);

  const handleClick = useCallback(() => {
    toggleScreen(!visible);
  }, [toggleScreen, visible]);

  return (
    <Interactive onClick={handleClick} className={cn(styles.button, {
      [styles.active]: visible,
    })}>
      <IconMenu className={styles.icon} />
    </Interactive>
  );
};
