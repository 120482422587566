import { BattleSceneLayer } from '../../scene/types';

import type { MarkerConfig } from './types';
import type { Entity } from '../../entity';

import { Plane } from '~/client/core/render-item/shape/plane';

import './resources';

export class Marker extends Plane {
  private entity: Entity;

  constructor(entity: Entity, { material, scale = 1.0 }: MarkerConfig) {
    super(entity.battle.scene, {
      position: { x: 0, y: 0.05, z: 0 },
      material,
    });

    this.object.layers.set(BattleSceneLayer.Marker);

    this.setScale({ x: scale, y: scale, z: 1.0 });

    this.entity = entity;
    this.entity.renderItem.object.add(this.object);
  }
}
