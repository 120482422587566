import { Mesh, SkinnedMesh } from 'three';

import { GLTFLoader } from './loader';
import { Logger } from '../../logger';

import type { LoaderModel } from './loader/types';
import type { ModelBinaryData, ModelType } from '../types';
import type { LoadingManager, Object3D, Bone, BufferGeometry } from 'three';

export class AssetsModels {
  private static list: Map<ModelType, string> = new Map();

  private static models: Map<ModelType, LoaderModel> = new Map();

  public static binaries: Map<string, string> = new Map();

  public static add(type: ModelType, { gltf, bin }: ModelBinaryData) {
    if (this.list.has(type)) {
      throw Error(`Model '${type}' is already registered`);
    }

    this.list.set(type, gltf);

    const binPathParts = bin.path.split('/');
    this.binaries.set(bin.origin, binPathParts[binPathParts.length - 1]);
  }

  public static get(type: ModelType): LoaderModel {
    const model = this.models.get(type);
    if (!model) {
      throw Error(`Model '${type}' isn\`t loaded`);
    }

    return model;
  }

  public static clone(type: ModelType): Object3D {
    const model = this.get(type);
    const object = model.scene.clone();

    const sourceLookup = new Map<Object3D, Object3D>();
    const cloneLookup = new Map<Object3D, Object3D>();

    const parallelTraverse = (a: Object3D, b: Object3D, callback: (a: Object3D, b: Object3D) => void) => {
      callback(a, b);

      for (let i = 0; i < a.children.length; i ++) {
        parallelTraverse(a.children[i], b.children[i], callback);
      }
    };

    parallelTraverse(model.scene, object, (source, clone) => {
      sourceLookup.set(clone, source);
      cloneLookup.set(source, clone);
    });

    object.traverse((mesh) => {
      if (mesh instanceof SkinnedMesh) {
        const sourceMesh = sourceLookup.get(mesh) as SkinnedMesh;
        if (sourceMesh) {
          const sourceBones = sourceMesh.skeleton.bones;

          mesh.skeleton = sourceMesh.skeleton.clone();
          mesh.bindMatrix.copy(sourceMesh.bindMatrix);

          mesh.skeleton.bones = sourceBones.map((bone) => (
            cloneLookup.get(bone) as Bone
          ));

          mesh.bind(mesh.skeleton, mesh.bindMatrix);
        }
      }
    });

    return object;
  }

  public static load(manager: LoadingManager) {
    const loaderModel = new GLTFLoader(manager);
    const tasks: Promise<void>[] = Array(this.list.size);

    this.list.forEach((path, type) => {
      tasks.push(
        loaderModel.loadAsync(path).then((model) => {
          this.models.set(type, model);
        }).catch((error) => {
          Logger.error(`Failed to load model '${type}'`, error as Error);
        }),
      );
    });

    return tasks;
  }

  public static getGeometry(type: ModelType): Nullable<BufferGeometry> {
    let geometry: Nullable<BufferGeometry> = null;

    this.get(type).scene.traverse((mesh) => {
      if (mesh instanceof Mesh) {
        geometry = mesh.geometry;
      }
    });

    return geometry;
  }
}
