import cn from 'classnames';
import React from 'react';

import { Text } from '../text';

import styles from './styles.module.scss';

type Props = {
  size?: 's' | 'm';
  error: {
    active: boolean;
    message?: Nullable<string>;
  };
};

export const ErrorPlate: React.FC<Props> = ({ size = 'm', error }) => {
  return error.message && (
    <div className={cn(styles.error, styles[`size-${size}`], {
      [styles.animate]: error.active,
    })}>
      <Text size='s'>{error.message}</Text>
    </div>
  );
};
