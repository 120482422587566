import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useCallback } from 'react';

import { useBuilding } from '../../../../hooks/use-building';

import type { Battle } from '~/client/battle';
import type { BuildingMessage } from '~/shared/battle/entity/building/types';

import { Resources } from '~/client/battle/ui/components/resources';
import { useSelfPlayerSchema } from '~/client/battle/ui/hooks/use-self-player-schema';
import { Device } from '~/client/core/device';
import { KeyboardKey } from '~/client/core/input/keyboard/ui/components/key';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { BuildingUtils } from '~/shared/battle/entity/building/utils';

import styles from './styles.module.scss';

import IconLock from './icons/lock.svg';

type Props = {
  icon: SVGIcon;
  message: BuildingMessage;
  hotkey?: string;
  cost?: number;
  minLevel?: number;
  disabled?: boolean;
  blink?: boolean;
  type: 'action' | 'break';
};

export const Control: React.FC<Props> = ({
  icon, message, hotkey, cost, disabled, minLevel, blink, type,
}) => {
  const battle = useRoom<Battle>();
  const building = useBuilding();
  const player = useSelfPlayerSchema();
  const resources = useSchemaValue(player, 'resources');

  const hasLevel = (
    !minLevel ||
    BuildingUtils.hasLevelToUpgrade(building.schema, battle.state, player)
  );

  const handleClick = useCallback(() => {
    if (!building.disposed) {
      building.doAction(message);
    }
  }, []);

  return (
    <Interactive
      onClick={handleClick}
      disabled={disabled || !hasLevel}
      className={cn(styles.wrapper, styles[`type-${type}`], {
        [styles.disabled]: disabled || !hasLevel,
      })}
    >
      <div className={cn(styles.container, {
        [styles.blink]: blink,
      })}>
        {disabled ? (
          <Icon type={icon} className={styles.icon} />
        ) : (
          <Section direction='vertical' align='center' gap={6}>
            {hasLevel ? (
              <Icon type={icon} className={styles.icon} />
            ) : (
              <>
                <IconLock />
                <Text size='xs'>
                  <b>{minLevel}</b> LVL
                </Text>
              </>
            )}
          </Section>
        )}
        {Device.isDesktop && hotkey && (
          <div className={styles.hotkey}>
            <KeyboardKey size='s'>{hotkey.replace('Key', '')}</KeyboardKey>
          </div>
        )}
      </div>
      {!disabled && hasLevel && !!cost && (
        <Resources value={cost} className={cn(styles.cost, {
          [styles.less]: resources < cost,
        })} />
      )}
    </Interactive>
  );
};
