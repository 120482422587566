import React from 'react';

import { PARAMETER_ICON } from './const';

import type { ParameterType } from './types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Amount } from '~/client/core/ui/components/amount';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  value: number;
  type: ParameterType;
};

export const Parameter: React.FC<Props> = ({ type, value }) => {
  const language = useLanguage();

  return (
    <Section direction='vertical' gap={10} className={styles.wrapper}>
      <Text size='2xs' uppercase spacing className={styles.label}>{language(`Parameter${type}`)}</Text>
      <Amount size='m' value={value} icon={PARAMETER_ICON[type]} color='#4ae49d' />
    </Section>
  );
};

