import cn from 'classnames';
import { useSchemaValue } from 'colyseus-schema-hooks';
import React from 'react';

import { UPGRADE_ICON } from './const';

import type { UpgradeVariant } from '~/shared/battle/entity/unit/player/upgrades/types';

import { Card } from '~/client/battle/ui/components/card';
import { useSelfPlayer } from '~/client/battle/ui/hooks/use-self-player';
import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Text } from '~/client/core/ui/components/text';
import { UPGRADE_MAX_LEVEL, UPGRADE_COSTS } from '~/shared/battle/entity/unit/player/upgrades/const';

import styles from './styles.module.scss';

type Props = {
  variant: UpgradeVariant;
};

export const Variant: React.FC<Props> = ({ variant }) => {
  const player = useSelfPlayer();
  const audio = useAudio();
  const language = useLanguage();

  const upgrades = useSchemaValue(player.schema, 'upgrades');
  const experience = useSchemaValue(player.schema, 'experience');

  const stages = Array(UPGRADE_MAX_LEVEL).fill(0);
  const level = upgrades.get(variant) ?? 1;
  const cost = UPGRADE_COSTS[variant].get(level);
  const hasExperience = experience >= cost;
  const hasMaxLevel = level === UPGRADE_MAX_LEVEL;

  const handleClick = () => {
    audio.play(AudioType.PlayerUpgrade);
    player.upgrade(variant);
  };

  return (
    <Card
      onClick={handleClick}
      disabled={hasMaxLevel || !hasExperience}
      locked={hasMaxLevel}
      alarm={!hasMaxLevel && !hasExperience}
    >
      <Card.Graphic>
        <Icon type={UPGRADE_ICON[variant]} />
      </Card.Graphic>
      <Card.Content>
        <Card.Title>{language(variant)}</Card.Title>
        {hasMaxLevel ? (
          <Text size='2xs' uppercase>
            {language('MaxLevel')}
          </Text>
        ) : (
          <div className={cn(styles.cost, {
            [styles.less]: !hasExperience,
          })}>
            <div className={styles.amount}>{cost}</div>
            <div className={styles.postfix}>EXP</div>
          </div>
        )}
      </Card.Content>
      <div className={styles.stages}>
        {stages.map((_, i) => (
          <div
            key={i}
            className={cn(styles.stage, {
              [styles.active]: level > i,
            })}
          />
        ))}
      </div>
    </Card>
  );
};
