import React, { useCallback } from 'react';

import type { Relay } from '~/client/relay';

import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Button } from '~/client/core/ui/components/button';
import { UserAuthRequest } from '~/shared/core/user/auth/types';

import IconLogout from './icons/logout.svg';

export const ButtonLogout: React.FC = () => {
  const room = useRoom<Relay>();
  const language = useLanguage();

  const logout = useRequest(UserAuthRequest.Logout);

  const handleClick = useCallback(() => {
    logout.fetch().then(() => {
      Client.removeAuthToken();
      room.refreshUI();
    });
  }, []);

  return (
    <Button icon={IconLogout} size='s' view='transparent' onClick={handleClick} >
      {language('LeaveAccount')}
    </Button>
  );
};
