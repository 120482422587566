import cn from 'classnames';
import React from 'react';

import { Controls } from './controls';
import { HealthAmount } from './health-amount';
import { HealthBar } from './health-bar';
import { LevelBar } from './level-bar';
import { BuildingPanelVisible } from '../../../types';
import { useBuilding } from '../../hooks/use-building';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { BuildingVariant } from '~/shared/battle/entity/building/types';

import styles from './styles.module.scss';

type Props = {
  visible: BuildingPanelVisible;
};

export const BuildingPanel: React.FC<Props> = ({ visible }) => {
  const building = useBuilding();
  const language = useLanguage();

  return (
    <div className={styles.translator}>
      {visible === BuildingPanelVisible.None ? (
        building.schema.variant === BuildingVariant.Base && (
          <HealthAmount />
        )
      ) : (
        <div className={cn(styles.wrapper, {
          [styles.full]: visible === BuildingPanelVisible.Full,
        })}>
          <div className={cn(styles.container, {
            [styles.selfOwn]: building.selfOwn,
            [styles.arrow]: visible === BuildingPanelVisible.Compact,
          })} >
            <Text size='s' bold uppercase>
              {language(`${building.schema.variant}Name`)}
            </Text>
            <Section direction="vertical" gap={6}>
              <HealthBar />
              <LevelBar />
            </Section>
          </div>
          {visible === BuildingPanelVisible.Full && (
            <Controls />
          )}
        </div>
      )}
    </div>
  );
};
