import cn from 'classnames';
import React, { useCallback } from 'react';

import { INVENTORY_ITEM_ICON } from '../../../const';
import { useInventoryItemSlot } from '../../../hooks/use-inventory-item-slot';

import type { DroidVariant } from '~/shared/battle/entity/unit/npc/droid/types';

import { AudioType } from '~/client/core/audio/types';
import { useAudio } from '~/client/core/audio/ui/hooks/use-audio';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRequest } from '~/client/core/request/ui/hooks/use-request';
import { Crystals } from '~/client/core/ui/components/amount/crystals';
import { Button } from '~/client/core/ui/components/button';
import { Icon } from '~/client/core/ui/components/icon';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';
import { InventoryRequest } from '~/shared/core/inventory/types';
import { InventoryUtils } from '~/shared/core/inventory/utils';

import styles from './styles.module.scss';

type Props = {
  item: DroidVariant;
  have?: boolean
};

export const InventoryItem: React.FC<Props> = ({ item, have }) => {
  const language = useLanguage();
  const audio = useAudio();

  const buyItem = useRequest(InventoryRequest.BuyItem);
  const updateSlot = useRequest(InventoryRequest.UpdateSlot);

  const crystals = useUserData('crystals');

  const usedSlot = useInventoryItemSlot(item);

  const active = usedSlot !== null;
  const cost = InventoryUtils.getItemCost(item);

  const handleClickBuy = useCallback(async () => {
    if (have) {
      return;
    }

    if (crystals < cost) {
      audio.play(AudioType.Error);
      return;
    }

    await buyItem.fetch({ item });

    audio.play(AudioType.Purchase);
  }, []);

  const handleClickSelect = useCallback(() => {
    if (!have || active) {
      return;
    }

    audio.play(AudioType.Select);

    updateSlot.fetch({
      item,
      slot: 0,
    });
  }, [have, active]);

  return (
    <div className={cn(styles.wrapper, {
      [styles.have]: have,
      [styles.active]: active,
    })}>
      <div className={styles.frame}>
        <Icon type={INVENTORY_ITEM_ICON[item]} className={styles.icon} />
      </div>
      <Section direction='vertical' align='center' gap={8}>
        <Text view='primary' size='2xl' bold uppercase>
          {language(`${item}Name`)}
        </Text>
        <Text size='m' align='center' wrap className={styles.description}>
          {language(`${item}Description`)}
        </Text>
      </Section>
      {have ? (
        active ? (
          <Button onClick={handleClickSelect} view='accent' disabled>
            {language('Selected')}
          </Button>
        ) : (
          <Button onClick={handleClickSelect} loading={updateSlot.loading}>
            {language('Select')}
          </Button>
        )
      ) : (
        <Button onClick={handleClickBuy} loading={buyItem.loading}>
          {language('Buy')} <Crystals value={cost} size='s' />
        </Button>
      )}
    </div>
  );
};
