import { Events } from 'make-event';
import { Vector3 } from 'three';

import { ENTITY_INTERPOLATION_LERP } from './const';
import { Marker } from '../minimap/marker';

import type { Battle } from '..';
import type { MarkerConfig } from '../minimap/marker/types';
import type { Vector3Like } from 'three';
import type { AudioType } from '~/client/core/audio/types';
import type { RenderItem } from '~/client/core/render-item';
import type { EntityDamagePayload, EntityMessagePayload, EntitySchema } from '~/shared/battle/entity/types';

import { MaterialType } from '~/client/core/assets/materials/types';
import { Client } from '~/client/core/client';
import { Interpolation } from '~/client/core/interpolation';
import { Logger } from '~/client/core/logger';
import { Messages } from '~/client/core/messages';
import { Prediction } from '~/client/core/prediction';
import { EntityMessage } from '~/shared/battle/entity/types';
import { Timeouts } from '~/shared/core/time/timeouts';

export abstract class Entity {
  public readonly schema: EntitySchema;

  public readonly battle: Battle;

  public readonly renderItem: RenderItem;

  public readonly selfOwn: boolean;

  protected moving: boolean = false;

  private marker: Nullable<Marker>;

  protected readonly velocity: Vector3 = new Vector3();

  private readonly featurePosition: Vector3 = new Vector3();

  public disposed: boolean = false;

  protected readonly timeouts: Timeouts = new Timeouts();

  protected readonly messages: Messages<EntityMessagePayload>;

  protected prediction: Nullable<Prediction> = null;

  protected interpolation: Nullable<Interpolation> = null;

  protected onDamage?(payload: EntityDamagePayload): void;

  protected onChangeMoveState?(moving: boolean): void;

  protected applyPrediction?(): void;

  public readonly onUpdate = Events.make();

  constructor(battle: Battle, renderItem: RenderItem, schema: EntitySchema) {
    this.battle = battle;
    this.renderItem = renderItem;
    this.schema = schema;
    this.selfOwn = (schema.ownerId === Client.sessionId);

    this.renderItem.object.uuid = this.schema.id;
    this.renderItem.object.name = this.constructor.name;

    this.messages = new Messages(this.battle.origin, this.battle.messagesBuffer);
    this.messages.setChannel(this.schema.id);

    this.setPosition(schema.position);
    this.setVelocity(schema.velocity);

    this.listenSchemaPosition();
    this.listenSchemaVelocity();
    this.listenSchemaVisible();

    this.messages.on(EntityMessage.Damage, (payload) => {
      this.onDamage?.(payload);
    });

    this.schema.onRemove(() => {
      this.destroy();
    });

    this.battle.entities.set(this.schema.id, this);

    this.onSceneUpdate = this.onSceneUpdate.bind(this);
    this.battle.scene.onUpdate(this.onSceneUpdate);

    this.updateVisible();
  }

  public destroy(): void {
    if (this.disposed) {
      throw Error(`Entity ${this.constructor.name} is already destroyed`);
    }

    this.disposed = true;

    this.renderItem.destroy();
    this.timeouts.clear();
    this.messages.clear();

    this.battle.entities.delete(this.schema.id);

    this.battle.scene.onUpdate.unsubscribe(this.onSceneUpdate);

    this.onUpdate.clear();

    this.removeUI();
    this.removeMarker();

    /**
     * TODO: https://github.com/neki-dev/izowave2/issues/14
     * These deletions of fields are fixing memory leak,
     * but need to find closures, which use that fields.
     */
    // // @ts-ignore
    // delete this.renderItem;
    // // @ts-ignore
    // delete this.schema;
    // // @ts-ignore
    // delete this.messages;
  }

  protected onSceneUpdate(): void {
    this.interpolate();
    this.timeouts.update();

    this.onUpdate.invoke();
  }

  protected setUI(ui: React.FC<{ target: any }>) {
    this.battle.setEntityUI(this, ui);
  }

  private removeUI() {
    this.battle.setEntityUI(this, null);
  }

  protected createMarker(config: Partial<MarkerConfig>) {
    if (this.marker) {
      Logger.warn('Entity marker is already created');
      return;
    }

    this.marker = new Marker(this, {
      ...config,
      material: config.material ?? (
        this.selfOwn
          ? MaterialType.MarkerSelf
          : MaterialType.MarkerOpponent
      ),
    });
  }

  protected removeMarker() {
    if (!this.marker) {
      return;
    }

    this.marker.destroy();
    this.marker = null;
  }

  public playAudio(type: AudioType) {
    this.battle.scene.audio.play3D(type, {
      scene: this.battle.scene,
      position: this.renderItem.position,
    });
  }

  public stopAudio(type: AudioType) {
    this.battle.scene.audio.stop(type);
  }

  protected enablePrediction() {
    if (this.interpolation) {
      throw Error('Unable to use prediction with interpolation');
    }

    this.prediction = new Prediction();
  }

  protected enableInterpolation() {
    if (this.prediction) {
      throw Error('Unable to use interpolation with prediction');
    }

    this.interpolation = new Interpolation();
    // this.interpolation.addState(this.schema.position);
    this.featurePosition.copy(this.schema.position);
  }

  private updateVisible() {
    this.renderItem.setVisible(
      this.schema.active
      && (this.selfOwn || this.schema.visibleForOpponent),
    );
  }

  public synchronize() {
    if (this.interpolation) {
      this.featurePosition.copy(this.schema.position);
      this.setPosition(this.featurePosition);
    }
  }

  private listenSchemaPosition() {
    this.schema.position.onChange(() => {
      if (this.interpolation) {
        // this.interpolation.addState(this.schema.position);
        this.featurePosition.copy(this.schema.position);
      } else {
        this.setPosition(this.schema.position);
        if (this.prediction) {
          this.applyPrediction?.();
        }
      }
    });
  }

  private listenSchemaVelocity() {
    this.schema.velocity.onChange(() => {
      if (this.prediction) {
        // Velocity already predicted
      } else {
        this.setVelocity(this.schema.velocity);
      }
    });
  }

  private listenSchemaVisible() {
    this.schema.listen('active', () => {
      this.updateVisible();
    });

    if (!this.selfOwn) {
      this.schema.listen('visibleForOpponent', () => {
        this.updateVisible();
      });
    }
  }

  public setPosition(position: Vector3Like) {
    this.renderItem.setPosition(position);
  }

  public setVelocity(velocity: Vector3Like) {
    this.velocity.copy(velocity);

    const moving = this.velocity.lengthSq() > 0;
    if (this.moving !== moving) {
      this.moving = moving;
      this.onChangeMoveState?.(moving);
    }
  }

  private interpolate() {
    if (!this.interpolation) {
      return;
    }

    const lerpFactor = ENTITY_INTERPOLATION_LERP * this.battle.scene.delta;
    this.renderItem.position.lerp(this.featurePosition, lerpFactor);

    // const timestamp = performance.now() - (1000 / RATE.UPDATE);
    // const position = this.interpolation.useState(timestamp);
    // if (position) {
    //   this.renderItem.position.copy(position);
    // }
  }
}
