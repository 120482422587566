import { Events } from 'make-event';
import { Vector2 } from 'three';

import { Input } from '..';
import { Device } from '../../device';

export class InputMouse {
  public static readonly position: Vector2 = new Vector2();

  public static readonly normalizedPosition: Vector2 = new Vector2();

  public static readonly onMouseMove = Events.make<MouseEvent>();
  public static readonly onMouseMoveWorld = Events.make<MouseEvent>();
  public static readonly onMouseClick = Events.make<MouseEvent>();
  public static readonly onMouseClickWorld = Events.make<MouseEvent>();
  public static readonly onMouseRelease = Events.make<MouseEvent>();
  public static readonly onMouseWheel = Events.make<WheelEvent>();

  public static addListeners() {
    this.listenMouseMove();
    this.listenMouseDown();
    this.listenMouseUp();
    this.listenMouseWheel();
  }

  public static setCursorPointer(state: boolean) {
    Device.getWrapper().style.cursor = state ? 'var(--cursor-pointer), auto' : '';
  }

  private static updatePosition(event: MouseEvent) {
    const positionOnScreen = Device.getPositionOnScreen({
      x: event.clientX,
      y: event.clientY,
    });

    this.position.copy(positionOnScreen);
    this.normalizedPosition.copy(
      Device.normalizePosition(this.position),
    );
  }

  private static listenMouseMove() {
    document.addEventListener('mousemove', (event) => {
      this.updatePosition(event);

      this.onMouseMove.invoke(event);

      if (Input.isWorldTarget(event)) {
        this.onMouseMoveWorld.invoke(event);
      }
    });
  }

  private static listenMouseDown() {
    document.addEventListener('mousedown', (event) => {
      this.onMouseClick.invoke(event);

      if (Input.isWorldTarget(event)) {
        this.onMouseClickWorld.invoke(event);
      }
    });
  }

  private static listenMouseUp() {
    document.addEventListener('mouseup', (event) => {
      this.onMouseRelease.invoke(event);
    });
  }

  private static listenMouseWheel() {
    document.addEventListener('wheel', (event) => {
      this.onMouseWheel.invoke(event as WheelEvent);
    }, {
      passive: false,
    });
  }
}
