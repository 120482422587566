import cn from 'classnames';
import React from 'react';

import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  children: string;
  size: 's' | 'm' | 'l'
};

export const KeyboardKey: React.FC<Props> = ({ children, size }) => (
  <div className={cn(styles.size, styles[size])}>
    <div className={styles.wrapper}>
      <div className={styles.key}>
        <Text size='m' uppercase spacing bold>{children}</Text>
      </div>
    </div>
  </div>
);

