import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  size: '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl';
  bold?: boolean;
  uppercase?: boolean;
  wrap?: boolean;
  view?: 'default' | 'primary' | 'secondary' | 'transparent' | 'dark' | 'brilliant';
  align?: 'left' | 'center' | 'right';
  spacing?: boolean;
  className?: string;
};

export const Text: React.FC<Props> = ({
  children,
  bold,
  uppercase,
  size,
  className,
  spacing,
  wrap,
  align = 'left',
  view = 'default',
}) => (
  <div className={cn(
    className,
    styles.text,
    styles[`align-${align}`],
    styles[`size-${size}`],
    styles[`view-${view}`], {
      [styles.bold]: bold,
      [styles.uppercase]: uppercase,
      [styles.wrap]: wrap,
      [styles.spacing]: spacing,
    })
  }>
    {children}
  </div>
);
