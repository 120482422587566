import React from 'react';

import { Text } from '~/client/core/ui/components/text';

export type TitleProps = {
  children: React.ReactNode;
};

export const Title: React.FC<TitleProps> = ({ children }) => (
  <Text size='xs' view='primary' bold uppercase spacing wrap>{children}</Text>
);
