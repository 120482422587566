import { FileLoader, Loader, LoaderUtils } from 'three';

import { GLTFBinary } from '../binary';
import { BINARY_EXTENSION_HEADER_MAGIC, BINARY_EXTENSION_NAME } from '../binary/const';
import { GLTFParser } from '../parser';

import type { LoaderRawData, LoaderParsedData, LoaderModel } from './types';

export class GLTFLoader extends Loader<LoaderModel> {
  load(
    url: string,
    onLoad: (model: LoaderModel) => void,
    onProgress: () => void,
    onError: (error: any) => void,
  ) {
    let resourcePath: string;
    if (this.resourcePath !== '') {
      resourcePath = this.resourcePath;
    } else if (this.path !== '') {
      const relativeUrl = LoaderUtils.extractUrlBase(url);
      resourcePath = LoaderUtils.resolveURL(relativeUrl, this.path);
    } else {
      resourcePath = LoaderUtils.extractUrlBase(url);
    }

    this.manager.itemStart(url);

    const _onLoad = (data: LoaderRawData) => {
      try {
        this.parse(data, resourcePath, (gltf) => {
          onLoad(gltf);
          this.manager.itemEnd(url);
        }, _onError);
      } catch (e) {
        _onError(e);
      }
    };

    const _onError = (error: any) => {
      if (onError) {
        onError(error);
      } else {
        console.error(error);
      }

      this.manager.itemError(url);
      this.manager.itemEnd(url);
    };

    const loader = new FileLoader(this.manager);

    loader.setPath(this.path);
    loader.setResponseType('arraybuffer');
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);

    loader.load(url, _onLoad, onProgress, _onError);
  }

  parse(
    data: LoaderRawData,
    path: string,
    onLoad: (model: LoaderModel) => void,
    onError: (error: any) => void,
  ) {
    let json: LoaderParsedData;
    const extensions: Record<string, GLTFBinary> = {};
    const textDecoder = new TextDecoder();

    if (typeof data === 'string') {
      json = JSON.parse(data);
    } else if (data instanceof ArrayBuffer) {
      const magic = textDecoder.decode(new Uint8Array(data, 0, 4));
      if (magic === BINARY_EXTENSION_HEADER_MAGIC) {
        try {
          extensions[BINARY_EXTENSION_NAME] = new GLTFBinary(data);
        } catch (error) {
          if (onError) {
            onError(error);
          }
          return;
        }
        json = JSON.parse(extensions[BINARY_EXTENSION_NAME].getContent());
      } else {
        json = JSON.parse(textDecoder.decode(data));
      }
    } else {
      json = data;
    }

    if (!json.asset || json.asset.version[0] < 2) {
      if (onError) {
        onError(new Error('Unsupported model asset version'));
      }
      return;
    }

    const parser = new GLTFParser(json, {
      path: path || this.resourcePath || '',
      crossOrigin: this.crossOrigin,
      requestHeader: this.requestHeader,
      manager: this.manager,
    });

    parser.fileLoader.setRequestHeader(this.requestHeader);

    parser.setExtensions(extensions);
    parser.parse(onLoad, onError);
  }

  parseAsync(data: LoaderRawData, path: string) {
    return new Promise((resolve, reject) => {
      this.parse(data, path, resolve, reject);
    });
  }
}
