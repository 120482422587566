import { useSchemaValue } from 'colyseus-schema-hooks';
import React, { useEffect, useRef, useState } from 'react';

import { WAVE_NOTICE_TIMEOUT } from './const';

import type { Battle } from '~/client/battle';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { Text } from '~/client/core/ui/components/text';
import { useNextEffect } from '~/client/core/ui/hooks/use-next-effect';
import { WAVE_SPAWN_BOSS_PER_WAVE } from '~/shared/battle/wave/const';

import styles from './styles.module.scss';

export const WaveNotice: React.FC = () => {
  const { wave } = useRoom<Battle>();
  if (!wave) {
    return null;
  }

  const language = useLanguage();

  const [text, setText] = useState<Nullable<string>>(null);

  const paused = useSchemaValue(wave.schema, 'paused');
  const going = useSchemaValue(wave.schema, 'going');

  const refTimeout = useRef<Nullable<Timer>>(null);

  const show = (text: string) => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }

    setText(text);

    refTimeout.current = setTimeout(() => {
      setText(null);
      refTimeout.current = null;
    }, WAVE_NOTICE_TIMEOUT);
  };

  useNextEffect(() => {
    if (going) {
      if (wave.schema.number % WAVE_SPAWN_BOSS_PER_WAVE === 0) {
        show(language('BossWaveStarted'));
      } else {
        show(language('WaveStarted', [wave.schema.number]));
      }
    } else {
      show(language('WaveCompleted', [wave.schema.number - 1]));
    }
  }, [going]);

  useNextEffect(() => {
    if (!paused) {
      show(language('PrepareForAttack'));
    }
  }, [paused]);

  useEffect(() => {
    return () => {
      if (refTimeout.current) {
        clearTimeout(refTimeout.current);
      }
    };
  }, []);

  return text && (
    <Text size="xl" bold uppercase className={styles.text}>
      {text}
    </Text>
  );
};
