import { Vector3, Raycaster, DirectionalLight, Mesh } from 'three';

import { BATTLE_SCENE_BACKGROUND_COLOR, BATTLE_SCENE_CAMERA_ANGLE, BATTLE_SCENE_CAMERA_DISTANCE, BATTLE_SCENE_LIGHT } from './const';
import { BattleSceneLayer } from './types';
import { BUILDING_MODELS } from '../entity/building/const';

import type { Battle } from '..';
import type { Building } from '../entity/building';
import type { Object3D, Vector2 } from 'three';
import type { InputTouchChannel } from '~/client/core/input/touch/channel';

import { Assets } from '~/client/core/assets';
import { MaterialType } from '~/client/core/assets/materials/types';
import { ModelType } from '~/client/core/assets/types';
import { Device } from '~/client/core/device';
import { InputMouse } from '~/client/core/input/mouse';
import { InputTouch } from '~/client/core/input/touch';
import { Scene } from '~/client/core/scene';
import { Snap } from '~/client/core/snap';

export class BattleScene extends Scene {
  private readonly battle: Battle;

  public readonly buildingsLinks: Map<string, Building> = new Map();
  public selectedBuilding: Nullable<Building> = null;
  public hoveredBuilding: Nullable<Building> = null;

  public readonly snaps: Map<string, string> = new Map();

  private readonly raycaster: Raycaster = new Raycaster();

  constructor(battle: Battle) {
    super({
      light: BATTLE_SCENE_LIGHT,
      backgroundColor: BATTLE_SCENE_BACKGROUND_COLOR,
    });

    this.onMouseClick = this.onMouseClick.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchWorld = this.onTouchWorld.bind(this);

    this.battle = battle;

    this.raycaster.layers.enable(BattleSceneLayer.Building);
    this.camera.layers.disable(BattleSceneLayer.Marker);

    this.camera.setAngle(BATTLE_SCENE_CAMERA_ANGLE);
    this.camera.setDistance(BATTLE_SCENE_CAMERA_DISTANCE);
    if (Device.isMobile) {
      this.camera.setTargetOffset(-0.5);
    }

    this.generatePlayerAvatar();
    this.generateBuildingsPreview();
    this.addLocalLight();

    this.battle.state.listen('paused', (paused) => {
      this.paused = paused;
    });
  }

  override destroy(): void {
    super.destroy();

    this.toggleControls(false);
  }

  public toggleControls(state: boolean) {
    if (state) {
      InputTouch.onTouchWorld(this.onTouchWorld);
      InputMouse.onMouseClickWorld(this.onMouseClick);
      InputMouse.onMouseMoveWorld(this.onMouseMove);
    } else {
      InputTouch.onTouchWorld.unsubscribe(this.onTouchWorld);
      InputMouse.onMouseClickWorld.unsubscribe(this.onMouseClick);
      InputMouse.onMouseMoveWorld.unsubscribe(this.onMouseMove);
    }
  }

  private onTouchWorld(touch: InputTouchChannel) {
    const building = this.getBuildingByNormalizedPosition(touch.normalizedPosition);
    if (building === this.selectedBuilding) {
      return;
    }

    touch.onRelease(() => {
      if (touch.shifted) {
        return;
      }

      if (this.selectedBuilding) {
        this.selectedBuilding.clickOutside();
        this.selectedBuilding = null;
      }

      if (
        building &&
        // TODO: Unsubscribe from event on building destroy
        !building.disposed
      ) {
        const toHandle = building.click();
        if (toHandle) {
          this.selectedBuilding = building;
        }
      }
    });
  }

  private onMouseClick(): void {
    if (this.hoveredBuilding) {
      if (this.hoveredBuilding === this.selectedBuilding) {
        return;
      }

      if (this.selectedBuilding) {
        this.selectedBuilding.clickOutside();
        this.selectedBuilding = null;
      }

      const toHandle = this.hoveredBuilding.click();
      if (toHandle) {
        this.selectedBuilding = this.hoveredBuilding;
      }
    } else if (this.selectedBuilding) {
      this.selectedBuilding.clickOutside();
      this.selectedBuilding = null;
    }
  }

  private onMouseMove(): void {
    const building = this.getBuildingByNormalizedPosition(InputMouse.normalizedPosition);
    if (building === this.hoveredBuilding) {
      return;
    }

    if (this.hoveredBuilding) {
      this.hoveredBuilding.unhover();
      this.hoveredBuilding = null;
    }

    if (building) {
      this.hoveredBuilding = building;
      this.hoveredBuilding.hover();
    }

    InputMouse.setCursorPointer(
      Boolean(this.hoveredBuilding),
    );
  }

  private getBuildingByNormalizedPosition(position: Vector2) {
    let building: Nullable<Building> = null;

    this.raycaster.setFromCamera(position, this.camera);
    const [intersect] = this.raycaster.intersectObject(this);
    if (intersect) {
      let object: Nullable<Object3D> = intersect.object;
      while (!building) {
        if (!object) {
          break;
        }

        building = this.buildingsLinks.get(object.uuid) ?? null;
        object = object.parent;
      }
    }

    return building;
  }

  private addLocalLight() {
    const directionalLight = new DirectionalLight(0xffffff, 1.0);
    directionalLight.position.set(0, 10, 5);
    this.add(directionalLight);
  }

  private generatePlayerAvatar() {
    const snap = new Snap({
      camera: new Vector3(0, 1, 2.4),
      width: 128,
      height: 128,
    });

    const object = snap.addModel({
      model: ModelType.Player,
      material: MaterialType.Unit,
      offset: { x: 0, y: -0.57, z: 0 },
    });

    const material = Assets.getMaterial(MaterialType.Self);
    object.traverse((mesh) => {
      if (mesh instanceof Mesh) {
        if (mesh.name === 'BodyMesh') {
          mesh.material = material;
        } else if (mesh.name === 'Backpack') {
          mesh.visible = false;
        }
      }
    });

    this.snaps.set('PlayerAvatar', snap.export());
    snap.remove(object);

    snap.remove();
  }

  private generateBuildingsPreview() {
    const snap = new Snap({
      camera: new Vector3(6, 3, 6),
      width: 60,
      height: 80,
    });

    Object.entries(BUILDING_MODELS).forEach(([variant, model]) => {
      const object = snap.addModel({
        model,
        material: MaterialType.Building,
      });
      this.snaps.set(variant, snap.export());
      snap.remove(object);
    });

    snap.remove();
  }
}
