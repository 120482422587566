import cn from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { useUserData } from '../../../hooks/use-user-data';
import { PageState } from '../../pages/state';
import { PageType } from '../../pages/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Icon } from '~/client/core/ui/components/icon';
import { Interactive } from '~/client/core/ui/components/interactive';
import { Text } from '~/client/core/ui/components/text';

import styles from './styles.module.scss';

type Props = {
  type: PageType;
  icon: SVGIcon;
  authorizedOnly?: boolean;
};

export const MenuItem: React.FC<Props> = ({ type, icon, authorizedOnly }) => {
  const authorized = useUserData('authorized');
  const language = useLanguage();

  const [page, setPage] = useRecoilState(PageState);

  const handleClick = useCallback(() => {
    if (!authorizedOnly || authorized) {
      setPage(type);
    } else {
      setPage(PageType.Auth);
    }
  }, [authorized]);

  return (
    <Interactive
      onClick={handleClick}
      className={cn(styles.container, {
        [styles.active]: page === type,
      })}
    >
      <Icon type={icon} className={styles.icon} />
      {/* @ts-ignore */}
      <Text size='xl' bold uppercase>{language(type)}</Text>
    </Interactive>
  );
};
