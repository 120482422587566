import React from 'react';

import type { SkillSchema, SkillVariant } from '~/shared/battle/entity/unit/player/skill/types';

import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { Section } from '~/client/core/ui/components/section';
import { Text } from '~/client/core/ui/components/text';
import { SkillType } from '~/shared/battle/entity/unit/player/skill/types';

import styles from './styles.module.scss';

type Props = {
  variant: SkillVariant;
  skill: SkillSchema;
};

export const SkillInfo: React.FC<Props> = ({ variant, skill }) => {
  const language = useLanguage();

  return (
    <Section direction='vertical' gap={8} className={styles.container}>
      <Text size='s' view='primary' bold uppercase wrap>
        {language(`${variant}Name`)}
      </Text>
      <Text size='xs' wrap>
        {language(`${variant}Description`)}
      </Text>
      <Text size='2xs' className={styles.addon}>
        {skill.type === SkillType.Active
          ? language('SkillDuration', [skill.duration / 1000])
          : language('PassiveSkill')
        }
      </Text>
    </Section>
  );
};

