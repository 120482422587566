import React from 'react';
import { useRecoilState } from 'recoil';

import { OpponentWaitingState } from '../state';

import type { Battle } from '~/client/battle';

import { Client } from '~/client/core/client';
import { useLanguage } from '~/client/core/language/ui/hooks/use-language';
import { useRoom } from '~/client/core/room/ui/hooks/use-room';
import { SDK } from '~/client/core/sdk';
import { SDKAdType } from '~/client/core/sdk/types';
import { Button } from '~/client/core/ui/components/button';
import { Section } from '~/client/core/ui/components/section';
import { BattleMode } from '~/shared/battle/types';
import { RoomType } from '~/shared/core/room/types';

import IconAds from './icons/ads.svg';
import IconLoad from './icons/load.svg';

export const Buttons: React.FC = () => {
  const battle = useRoom<Battle>();
  const language = useLanguage();

  const [, serOpponentWaiting] = useRecoilState(OpponentWaitingState);

  const save = (
    battle.state.mode === BattleMode.Single &&
    battle.state.wave.number > 1
  );

  const handleClickPlayAgain = () => {
    if (battle.state.mode === BattleMode.Online) {
      serOpponentWaiting(true);
    } else {
      battle.restart(false);
      Client.setLoading();
    }
  };

  const handleClickLoadSave = async () => {
    await SDK.showAd(SDKAdType.Rewarded);
    battle.restart(true);
    Client.setLoading();
  };

  const handleClickMenu = () => {
    Client.joinRoomByType(RoomType.Relay);
  };

  return (
    <Section direction="horizontal" align="center" gap={16}>
      {save && (
        <Button
          onClick={handleClickLoadSave}
          size="l"
          view="accent"
          icon={SDK.enabled ? IconAds : IconLoad}
        >
          {language('LoadSave')}
        </Button>
      )}
      <Button
        onClick={handleClickPlayAgain}
        size="l"
        view={save ? 'default' : 'accent'}
      >
        {language('PlayAgain')}
      </Button>
      <Button onClick={handleClickMenu} size='l'>
        {language('MainMenu')}
      </Button>
    </Section>
  );
};
