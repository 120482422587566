import cn from 'classnames';
import React from 'react';

import type { LeaderboardRecord } from '~/shared/relay/leaderboard/types';

import { Score } from '~/client/core/ui/components/amount/score';
import { Waves } from '~/client/core/ui/components/amount/waves';
import { Text } from '~/client/core/ui/components/text';
import { useUserData } from '~/client/relay/ui/hooks/use-user-data';

import styles from './styles.module.scss';

type Props = LeaderboardRecord;

export const Record: React.FC<Props> = ({ place, name, score, waves }) => {
  const currentName = useUserData('name');
  const self = name === currentName;

  return (
    <tr className={styles.row}>
      <td width='100%'>
        <div className={styles.info}>
          <div className={cn(styles.place, {
            [styles.self]: self,
          })}>
            <Text size='s' bold>{place}</Text>
          </div>
          <Text size='m'>{name}</Text>
        </div>
      </td>
      <td>
        <Score value={score}/>
      </td>
      <td>
        <Waves value={waves}/>
      </td>
    </tr>
  );
};
