import cn from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  visible?: boolean;
  blur?: boolean;
  children: React.ReactNode;
};

export const Overlay: React.FC<Props> = ({ visible = true, blur, children }) => (
  <div className={cn(styles.overlay, {
    [styles.visible]: visible,
    [styles.blur]: blur,
  })}>
    {children}
  </div>
);
